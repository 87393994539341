import React, { ReactElement } from "react";
import DropdownMenu from "../../../DropdownMenu";
import { UIKitMode } from "../../../shared/Types";
import { UIKitContainer } from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import { HorizontalAlignment, UIKitContainerLayoutType } from "../../Container";

const ControlContainerRowAlignment = (): ReactElement => {
    let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);
    
    const rowLayoutTypes: string[] = Object.values(HorizontalAlignment);

    if (
      useUIKitTreeContext.selectedUIKitComponent &&
      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
    ) {
      let uiKitContainer =
        useUIKitTreeContext.selectedUIKitComponent as UIKitContainer;

      return (
        <>
          {uiKitContainer.layoutType == UIKitContainerLayoutType.Row && (
              <div className="w-full mt-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Container Elements Alignment
                </label>
                <div className="flex mt-2">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className={"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.rowLayoutType == HorizontalAlignment.LeftAligned ? " bg-gray-100" : "bg-white")}
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.rowLayoutType = HorizontalAlignment.LeftAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Left Aligned
                  </button>
                  <button
                    type="button"
                    className={"relative -ml-px inline-flex  items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.rowLayoutType == HorizontalAlignment.CenterAligned ? " bg-gray-100" : "bg-white") }
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.rowLayoutType = HorizontalAlignment.CenterAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Center Aligned
                  </button>
                  <button
                    type="button"
                    className={"relative -ml-px inline-flex rounded-r-md items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.rowLayoutType == HorizontalAlignment.RightAligned ? " bg-gray-100" : "bg-white") }
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.rowLayoutType = HorizontalAlignment.RightAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Right Aligned
                  </button>
                </span>
                </div>
              </div>
          )}
        </>
      );
    }

    return <></>;
}

export default ControlContainerRowAlignment;