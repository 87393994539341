import { UIKitComponent, UIKitSerializable } from "../../shared/UIKitComponent";

export class AppEnvironment extends UIKitSerializable {
    id: string;
    name: string;
    componentTree?: UIKitComponent;
    liveURL?: string;
    
    constructor(id: string, name: string) {
        super();
        this.id = id;
        this.name = name;
    }

    toJSONDocument() {
        return {
            id: this.id,
            name: this.name,
        };
    }
}