import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";

import "./App.css";
import { BrowserRouter, Link, Navigate, NavLink, Route, Router, Routes, useLocation } from "react-router-dom";
import UIEditor from "./UIEditor";
import { UIKitMode } from "./shared/Types";
import DataEditor from "./DataEditor";

import Login from "./Login";
import Player, { PlayerMode } from "./Player";
import Signup from "./Signup";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/signup/:email?" element={<Signup></Signup>}></Route>
        <Route
          path="/:id"
          element={
            <>
              <Player mode={PlayerMode.Live}></Player>
            </>
          }
        ></Route>
        <Route path="/editor">
          <Route
            path="builder/:id"
            element={
              <>
                <UIEditor mode={UIKitMode.Edit}></UIEditor>
              </>
            }
          ></Route>
          <Route
            path="builder/new_app"
            element={
              <>
                <UIEditor mode={UIKitMode.Edit} showAppWizardModal={true}></UIEditor>
              </>
            }
          ></Route>
          <Route
            path="builder/apps_list"
            element={
              <>
                <UIEditor mode={UIKitMode.Edit} showAppListModal={true}></UIEditor>
              </>
            }
          ></Route>
          <Route
            path="preview/:id"
            element={
              <>
                <Player mode={PlayerMode.Preview}></Player>
              </>
            }
          ></Route>
          <Route
            path="data/:id"
            element={
              <>
                <DataEditor></DataEditor>
              </>
            }
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
