import React, { ReactElement } from "react";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";

const ControlBaseHeight = (): ReactElement => {
  let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);

  return (
    <>
<>
      <div className="flex mt-4 flex-col">
        <div className="w-full">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Height
          </label>
        </div>

        <div className="flex flex-row mt-2 space-x-2">
          <input
            type="number"
            className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={useUIKitTreeContext.selectedUIKitComponent?.height}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (useUIKitTreeContext.selectedUIKitComponent) {
                useUIKitTreeContext.selectedUIKitComponent.height = parseInt(event.target.value);
                useUIKitTreeContext.updateUIKitTreeState();
                useUIKitTreeContext.updateComponentTree();
              }
            }}
          />

          <div className="flex flex-row space-x-2">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className={
                  "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" +
                  (useUIKitTreeContext.selectedUIKitComponent?.fixedHeight == true ? " bg-gray-100" : "bg-white")
                }
                onClick={() => {
                  if (useUIKitTreeContext.selectedUIKitComponent) {
                    useUIKitTreeContext.selectedUIKitComponent.setFixedHeight();
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Fixed
              </button>
              <button
                type="button"
                className={
                  "relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" +
                  (useUIKitTreeContext.selectedUIKitComponent?.fillHeightToParent == true ? " bg-gray-100" : "bg-white")
                }
                onClick={() => {
                  if (useUIKitTreeContext.selectedUIKitComponent) {
                    useUIKitTreeContext.selectedUIKitComponent.setFillHeight();
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Fill
              </button>
              <button
                type="button"
                className={
                  "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" +
                  (useUIKitTreeContext.selectedUIKitComponent?.fitHeightToContent == true ? " bg-gray-100" : "bg-white")
                }
                onClick={() => {
                  if (useUIKitTreeContext.selectedUIKitComponent) {
                    useUIKitTreeContext.selectedUIKitComponent.setFitHeightToContent();
                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Fit Content
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
    </>
  );
};

export default ControlBaseHeight;
