import { useMutation } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import { IUIKitTreeContext, ModalType, UIKitTreeContext, User } from "./UIKitTreeContext";
import React from "react";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let uiKitTreeContext: IUIKitTreeContext | null = React.useContext(UIKitTreeContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!uiKitTreeContext?.isLoggedOut) {
      let lastOpenedAppEnvironmentId = localStorage.getItem("lastOpenedAppEnvironmentId");
      if (lastOpenedAppEnvironmentId) {
        navigate(`/editor/builder/${lastOpenedAppEnvironmentId}`);
      }
    }
  }, [uiKitTreeContext?.isLoggedOut, uiKitTreeContext?.appEnvironment]);

  const signInUserRequest = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
        },
      }),
    });

    let token = response.headers.get("Authorization");

    if (!token) throw new Error("No token found");
    localStorage.setItem("token", token);

    return await response.json();
  };

  const signInUserRequestMutation = useMutation({
    mutationFn: signInUserRequest,
    onSuccess: (response) => {
      let parsedUser = JSON.parse(response.data);

      localStorage.setItem("userId", parsedUser.id);
      localStorage.setItem("userEmail", parsedUser.email);

      let user: User = {
        id: parsedUser.id,
        email: parsedUser.email,
      };

      uiKitTreeContext?.setCurrentUser(user);
      uiKitTreeContext?.setCurrentModalState(ModalType.AppsListModal);
      uiKitTreeContext?.setIsLoggedOut(false);

      navigate("/editor/builder/apps_list");
    },
  });

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-lg space-y-10">
          <div>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Buildify Login
            </h2>
            <h3 className="mt-10 text-center text-1xl font-semibold leading-9 tracking-tight text-gray-900">
              The no code builder for AI powered web apps - build your app in minutes by simply describing it!
            </h3>
          </div>
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Email address"
                onChange={(event) => setEmail(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    signInUserRequestMutation.mutate();
                  }
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    signInUserRequestMutation.mutate();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => signInUserRequestMutation.mutate()}
            >
              Sign in
            </button>
          </div>

          <p className="text-center text-sm leading-6 text-gray-500">
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Want to make an app? Sign up{" "}
              <b>
                <u>
                  <a href="/signup">here</a>
                </u>
              </b>
            </a>

            <p className="text-center text-sm leading-6 text-gray-500">
              <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                Any feature requests or feedback? Join the{" "}
                <b>
                  <u>
                    <a href="https://discord.gg/uFezy5gY">discord community!</a>
                  </u>
                </b>
              </a>
            </p>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
