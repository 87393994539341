import { useDroppable } from "@dnd-kit/core";
import { UIKitMode, UIProps } from "../shared/Types";
import { AirtableField, AirtableTableModel, ListItemFieldTypes } from "../ComponentProperties";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { UIKitComponent, UIKitComponentType, UIKitText } from "../shared/UIKitComponent";
import { UIKit } from "../utilities/UIKit";
import Text from "./Text";
import UIKitComponentOverlay from "./ComponentOverlay";
import { UIKitComponentLayoutResolver } from "../utilities/UIKitComponentLayoutResolver";

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

interface ListItemProps { 
  listItemType: ListItemFieldTypes;
  listItemField: AirtableField;
}
interface ListProps extends UIProps { 
  data?: AirtableTableModel;
  listComponents?: UIKitComponent[]
}

interface AirtableRecord {
  id: string;                     // Record ID
  createdTime: string;            // Timestamp in ISO format
  fields: {                        // Fields with key-value pairs (can be field names or IDs)
    [key: string]: any;           // Keys are strings representing field names or IDs, values are the cell values
  };
  commentCount?: number;           // Optional comment count if included in the request
}

interface AirtableRecordsResponse {
  offset?: string;                 // Optional offset for pagination
  records: AirtableRecord[];       // Array of Airtable records
}

export const ListComponent = (props: ListProps) => { 

  const [airTableRecords, setAirTableRecords] = useState<AirtableRecord[]>([]);
  
  const airTableRecordsQuery = useQuery({
    queryKey: ["airtableRecords"],
    queryFn: async () => {
      const response = await fetch(`${API_ENDPOINT_URL}/api/air_table/records/${"appDd8l9vmHZfM9ux"}/${props.data?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });
  
      const data = await response.json();
  

      return data;
    },
  });

  useEffect(() => {
    if (airTableRecordsQuery.isFetched && airTableRecordsQuery.data) {
        const records = (airTableRecordsQuery.data as AirtableRecordsResponse).records
        setAirTableRecords(records);
    }

  }, [airTableRecordsQuery.data, airTableRecordsQuery.isFetched]);


    const getRecordValue = (recordIndex: number, key: string): string | undefined => { 
      let returnString = ""
      if (airTableRecords) {
        airTableRecords.forEach((record: AirtableRecord, index) => {
          Object.keys(record.fields).forEach((fieldKey: string) => {
            if (fieldKey == key && index == recordIndex) {
              if (typeof record.fields[fieldKey] == "object") {
                console.warn("Field is an object so we can't display it")
              } else { 
                returnString = record.fields[fieldKey]
              }
            }
          })
          }
        )
      }

      return returnString
    }

    let { setNodeRef } = useDroppable({
        id: props.id,
      });


      // In edit mode setting the width and height to 100% means that any style set on the list is not respected so for now we won't bother setting it.
      return (
        <div
          id={props.id}
          ref={setNodeRef}
          style={{...props.style}}
        >
        {airTableRecordsQuery.isFetching && (
          <>
          <div className="bg-indigo-600 bg-transparent">
          <svg className="animate-spin h-6 w-6 mr-2" viewBox="0 0 24 24">
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="#ff793f"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              </div>
          </>
        )}
        {airTableRecords && airTableRecords.length > 0 && !airTableRecordsQuery.isFetching && airTableRecords.map((record: AirtableRecord, recordIndex) => {
          
          return (
            <div className="m-2">
          {props.listComponents?.map((component, index) => {
            return ( 
              <>
              {component.type == UIKitComponentType.Text && (component instanceof UIKitText) && component.data?.listItemField.name && airTableRecords && ( 
                <>
                  <UIKitComponentOverlay id={component.id} children={[<Text id={component.id} headingType={component.headingType} mode={UIKitMode.Edit} style={ UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                    component
                     )} text={getRecordValue(recordIndex, component.data?.listItemField.name ?? "")}></Text>]} mode={props.mode}  />
                
                </>
              )}
              </>
            )
          })}
            </div>
          )

        })}

        </div>
      );
}