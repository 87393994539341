import {
  UIKitAppViewContainer,
  UIKitButton,
  UIKitComponent,
  UIKitComponentType,
  UIKitContainer,
  UIKitText,
  UIKitTextInput,
  ChatComponent,
  List,
  HeadingType,
} from "../shared/UIKitComponent";
import Button from "../components/Button";
import uuid from "react-uuid";
import { ReactNode } from "react";
import React from "react";
import Container, {
  AlignToParentAlignment,
  AppViewContainer,
  VerticalAlignment,
  HorizontalAlignment,
  UIKitContainerLayoutType,
} from "../components/Container";
import { UIKitMode } from "../shared/Types";
import UIKitComponentOverlay from "../components/ComponentOverlay";
import { UIKitComponentLayoutResolver } from "./UIKitComponentLayoutResolver";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import { ListComponent } from "../components/List";

export namespace UIKit {
  export const generateUIKitComponentID = (
    uiKitComponentType: string
  ): string => {
    return `${uiKitComponentType}-${uuid()}`;
  };

  export const createReactNodeTreeFromUIKitComponent = (
    uiKitComponent: UIKitComponent,
    mode: UIKitMode
  ): ReactNode => {
    if (!uiKitComponent) return;

    return createReactNodeFromUIKitComponent(
      uiKitComponent,
      mode,
      createReactNode(uiKitComponent.children, mode)
    );
  };

  const createReactNode = (
    children: UIKitComponent[],
    mode: UIKitMode
  ): ReactNode => {
    let reactNodes: ReactNode[] = [];

    if (children.length > 0) {
      children.forEach((child: UIKitComponent) => {
        reactNodes.push(
          createReactNodeFromUIKitComponent(
            child,
            mode,
            createReactNode(child.children, mode)
          )
        );
      });
    }

    return reactNodes;
  };

  export const createReactNodeFromUIKitComponent = (
    component: UIKitComponent,
    mode: UIKitMode,
    children?: ReactNode
  ): ReactNode | undefined => {
    switch (component.type) {
      case UIKitComponentType.AppView:
        let rootContainer = React.createElement(
          AppViewContainer,
          {
            key: component.id,
            id: component.id,
            style: UIKitComponentLayoutResolver.resolveContainerLayout(
              component as UIKitAppViewContainer
            ),
            layoutType: (component as UIKitAppViewContainer).layoutType,
            rowLayoutType: (component as UIKitAppViewContainer).rowLayoutType,
            columnLayoutType: (component as UIKitAppViewContainer)
              .columnLayoutType,
            mode: mode,
          },
          children
        );
        return createComponentOverlayReactNode(component, rootContainer, mode);

      case UIKitComponentType.Container:
        const containerReactNode: ReactNode = React.createElement(
          Container,
          {
            key: component.id,
            id: component.id,
            style: {
              ...UIKitComponentLayoutResolver.resolveContainerLayout(
                component as UIKitContainer
              ),
              ...(UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                component
              ) as UIKitContainer),
            },
            layoutType: (component as UIKitContainer).layoutType,
            rowLayoutType: (component as UIKitAppViewContainer).rowLayoutType,
            columnLayoutType: (component as UIKitAppViewContainer)
              .columnLayoutType,
            mode: mode,
          },
          children
        );

        return createComponentOverlayReactNode(
          component,
          containerReactNode,
          mode
        );

      case UIKitComponentType.Button:
        if (component instanceof UIKitButton) {
          const buttonReactNode: ReactNode = React.createElement(Button, {
            key: component.id,
            id: component.id,
            style:
              UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                component
              ),
            mode: mode,
            text: component.text,
            onClick: () => { 
              (component as UIKitButton).promptBlockAction?.execute();
            }
          });

          return createComponentOverlayReactNode(
            component,
            buttonReactNode,
            mode
          );
        }
        break;

      case UIKitComponentType.Text:
        if (component instanceof UIKitText) {
          const textReactNode: ReactNode = React.createElement(Text, {
            key: component.id,
            id: component.id,
            style:
              UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                component
              ),
            mode: mode,
            text: component.text,
            headingType: component.headingType,
          });

          return createComponentOverlayReactNode(
            component,
            textReactNode,
            mode
          );
        }
        break;
      case UIKitComponentType.TextInput:
        if (component instanceof UIKitTextInput) {
          const textReactNode: ReactNode = React.createElement(TextInput, {
            key: component.id,
            id: component.id,
            style:
              UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                component
              ),
            mode: mode,
            multiLine: component.multiLine
          });

          return createComponentOverlayReactNode(
            component,
            textReactNode,
            mode
          );
        }
        break;
      case UIKitComponentType.List:
        if (component instanceof List) {
          const listReactNode: ReactNode = React.createElement(ListComponent, {
            key: component.id,
            id: component.id,
            style:
              UIKitComponentLayoutResolver.resolveBaseComponentLayout(
                component
              ),
            mode: mode,
            data: component.dataSource ? component.dataSource : undefined,
            listComponents: component.children
            })

            return createComponentOverlayReactNode(
              component,
              listReactNode,
              mode
            );
          }; 
          break;

        }
    };

  const createComponentOverlayReactNode = (
    component: UIKitComponent,
    childReactNode: ReactNode,
    mode: UIKitMode
  ): ReactNode => {
    if (mode == UIKitMode.Live) return childReactNode;

    return React.createElement(
      UIKitComponentOverlay,
      {
        id: component.id,
        children: [childReactNode],
        style:
          UIKitComponentLayoutResolver.resolveBaseComponentLayout(component),
        mode: UIKitMode.Edit,
      },
      childReactNode
    );
  };

  export const createUIKitComponent = (
    componentType: string,
    parentComponent: UIKitComponent,
    x: number,
    y: number
  ): UIKitComponent | undefined => {
    let uiKitComponentTypeForReactNode: UIKitComponentType;
    // TODO: Find a better way to convert the component name from the UI to the UI Kit name
    switch (componentType) {
      case "container":
        uiKitComponentTypeForReactNode = UIKitComponentType.Container;
        return new UIKitContainer(
          {
            type: uiKitComponentTypeForReactNode,
            id: UIKit.generateUIKitComponentID(uiKitComponentTypeForReactNode),
            name: "Container",
            parent: parentComponent ? parentComponent : undefined,
            children: [],
            verticalAlign: VerticalAlignment.TopAligned,
            horizontalAlign: HorizontalAlignment.LeftAligned,
            width: 300,
            height: 200,
            x: x,
            y: y,
            canDrag: true,
            fixedWidth: false,
            fixedHeight: true,
            fitWidthToContent: false,
            fitHeightToContent: false,
            fillWidthToParent: true,
            fillHeightToParent: false
          },
          UIKitContainerLayoutType.Row,
          HorizontalAlignment.LeftAligned,
          VerticalAlignment.TopAligned,
        );
      case "button":
        uiKitComponentTypeForReactNode = UIKitComponentType.Button;
        return new UIKitButton(
          {
            type: uiKitComponentTypeForReactNode,
            id: UIKit.generateUIKitComponentID(uiKitComponentTypeForReactNode),
            name: "Button",
            parent: parentComponent ? parentComponent : undefined,
            children: [],
            verticalAlign: VerticalAlignment.TopAligned,
            horizontalAlign: HorizontalAlignment.LeftAligned,
            width: 140,
            height: 40,
            x: x,
            y: y,
            canDrag: true,
            fixedWidth: false,
            fixedHeight: false,
            fitWidthToContent: true,
            fitHeightToContent: true,
            fillHeightToParent: false,
            fillWidthToParent: false,
          },
          "Edit me..."
        );
      case "text":
        uiKitComponentTypeForReactNode = UIKitComponentType.Text;
        return new UIKitText(
          {
            type: uiKitComponentTypeForReactNode,
            id: UIKit.generateUIKitComponentID(uiKitComponentTypeForReactNode),
            name: "Text",
            parent: parentComponent ? parentComponent : undefined,
            children: [],
            verticalAlign: VerticalAlignment.TopAligned,
            horizontalAlign: HorizontalAlignment.LeftAligned,
            width: 140,
            height: 40,
            x: x,
            y: y,
            canDrag: true,
            fixedWidth: false,
            fixedHeight: false,
            fitWidthToContent: true,
            fitHeightToContent: true,
            fillHeightToParent: false,
            fillWidthToParent: false,
          },
          "Edit me....",
          HeadingType.Heading3, 16
        );
      case "textInput":
        uiKitComponentTypeForReactNode = UIKitComponentType.TextInput;
        return new UIKitTextInput(
          {
            type: uiKitComponentTypeForReactNode,
            id: UIKit.generateUIKitComponentID(uiKitComponentTypeForReactNode),
            name: "Text Input",
            parent: parentComponent ? parentComponent : undefined,
            children: [],
            verticalAlign: VerticalAlignment.TopAligned,
            horizontalAlign: HorizontalAlignment.LeftAligned,
            width: 140,
            height: 40,
            x: x,
            y: y,
            canDrag: true,
            fixedWidth: true,
            fixedHeight: true,
            fitWidthToContent: false,
            fitHeightToContent: false,
            fillHeightToParent: false,
            fillWidthToParent: false,
          },
          "Edit me...."
        );
      
      case "list": 
      uiKitComponentTypeForReactNode = UIKitComponentType.List
      return new List(
        {
          type: uiKitComponentTypeForReactNode,
          id: UIKit.generateUIKitComponentID(uiKitComponentTypeForReactNode),
          name: "List",
          parent: parentComponent ? parentComponent : undefined,
          children: [],
          verticalAlign: VerticalAlignment.TopAligned,
          horizontalAlign: HorizontalAlignment.LeftAligned,
          width: 300,
          height: 250,
          x: x,
          y: y,
          canDrag: true,
          fixedWidth: false,
          fixedHeight: true,
          fitWidthToContent: false,
          fitHeightToContent: false,
          fillHeightToParent: false,
          fillWidthToParent: true,
        },
      )
    }

    return undefined;
  };
}
