import { CSSProperties } from "react";
import {
  VerticalAlignment,
  HorizontalAlignment,
  UIKitContainerLayoutType,
} from "../components/Container";
import {
  HeadingType,
  List,
  UIKitAppViewContainer,
  UIKitComponent,
  UIKitContainer,
  UIKitText,
} from "../shared/UIKitComponent";
import '@fontsource-variable/lexend';

export namespace UIKitComponentLayoutResolver {
  let rowLayoutTypeCSS: Record<HorizontalAlignment, string> = {
    LeftAligned: "start",
    CenterAligned: "center",
    RightAligned: "end",
  };

  let columnLayoutTypeCSS: Record<VerticalAlignment, string> = {
    TopAligned: "start",
    CenterAligned: "center",
    BottomAligned: "end",
  };

  export const resolveContainerLayout = (
    component: UIKitContainer
  ): CSSProperties => {
    let resolvedStyles: CSSProperties = {};

    switch (component.layoutType) {
      case UIKitContainerLayoutType.Row:
        {
          resolvedStyles.display = "flex";
          resolvedStyles.flexDirection = "row";
          resolvedStyles.justifyContent =
            rowLayoutTypeCSS[component.rowLayoutType];
          resolvedStyles.flexWrap = "wrap";
        }
        break;
      case UIKitContainerLayoutType.Column:
        {
          resolvedStyles.display = "flex";
          resolvedStyles.flexDirection = "column";
          resolvedStyles.justifyContent =
            columnLayoutTypeCSS[component.columnLayoutType];
        }
    }

    resolvedStyles.gap = `${component.flexGap}px`;

    resolvedStyles.paddingTop = `${component.paddingTop}px`;
    resolvedStyles.paddingBottom = `${component.paddingBottom}px`;
    resolvedStyles.paddingRight = `${component.paddingRight}px`;
    resolvedStyles.paddingLeft = `${component.paddingLeft}px`;

    resolvedStyles.backgroundImage = 'url(' + component.imageUrl + ')';
    resolvedStyles.backgroundRepeat = 'round';

    switch(component.wrapContent) {
      case true: 
        resolvedStyles.flexWrap = 'wrap';
        break;
      case false: 
        resolvedStyles.flexWrap = 'nowrap';
        break;
    }

    return resolvedStyles;
  };

  export const resolveBaseComponentLayout = (
    component: UIKitComponent
  ): CSSProperties => {
    let resolvedStyles: CSSProperties = {};

    if (component && component.parent) {
      if (
        (component.parent as UIKitContainer).layoutType ==
        UIKitContainerLayoutType.Row
      ) {
        switch (component.verticalAlign) {
          case VerticalAlignment.TopAligned:
            {
              resolvedStyles.alignSelf = "start";
            }
            break;
          case VerticalAlignment.CenterAligned:
            {
              resolvedStyles.alignSelf = "center";
            }
            break;
          case VerticalAlignment.BottomAligned:
            {
              resolvedStyles.alignSelf = "end";
            }
            break;
        }
      } else if (
        (component.parent as UIKitContainer).layoutType ==
        UIKitContainerLayoutType.Column
      ) {
        switch (component.horizontalAlign) {
          case HorizontalAlignment.LeftAligned:
            {
              resolvedStyles.alignSelf = "start";
            }
            break;
          case HorizontalAlignment.CenterAligned:
            {
              resolvedStyles.alignSelf = "center";
            }
            break;
          case HorizontalAlignment.RightAligned:
            {
              resolvedStyles.alignSelf = "end";
            }
            break;
        }
      } else if (
        (component.parent as UIKitContainer).layoutType ==
        UIKitContainerLayoutType.Fixed
      ) {
        resolvedStyles.position = "absolute";
        resolvedStyles.left = `${component.x}px`;
        resolvedStyles.top = `${component.y}px`;
      } else { 
        switch (component.horizontalAlign) {
          case HorizontalAlignment.LeftAligned:
            {
              resolvedStyles.alignSelf = "start";
            }
            break;
          case HorizontalAlignment.CenterAligned:
            {
              resolvedStyles.alignSelf = "center";
            }
            break;
          case HorizontalAlignment.RightAligned:
            {
              resolvedStyles.alignSelf = "end";
            }
            break;
        }

        switch (component.verticalAlign) {
          case VerticalAlignment.TopAligned:
            {
              resolvedStyles.alignSelf = "start";
            }
            break;
          case VerticalAlignment.CenterAligned:
            {
              resolvedStyles.alignSelf = "center";
            }
            break;
          case VerticalAlignment.BottomAligned:
            {
              resolvedStyles.alignSelf = "end";
            }
            break;
        }
      }
    }

    if (component.fixedWidth) {
      resolvedStyles.width = `${component.width}px`;
    } else if (component.fitWidthToContent) {
      resolvedStyles.width = "auto";
    } else if (component.fillWidthToParent) { 
      resolvedStyles.width = '100%'
    }

    if (component.fixedHeight) {
      resolvedStyles.height = `${component.height}px`;
    } else if (component.fitHeightToContent) {
      resolvedStyles.height = "auto";
    } else if (component.fillHeightToParent) { 
      resolvedStyles.height = '100%'
    }

    // We want the app view container to be resposive to editor's width and height
    if (component.isAppViewContainer()) {
      resolvedStyles.width = "100%";
      resolvedStyles.minWidth = "100%";
      
      resolvedStyles.height = "95%";
      resolvedStyles.minHeight = "95%";
    }

    if (component.style) { 
      resolvedStyles.backgroundColor = `rgba(${component.style.backgroundColor?.rgb.r}, ${component.style.backgroundColor?.rgb.g}, ${component.style.backgroundColor?.rgb.b}, ${component.style.backgroundColor?.rgb.a})`
    }

    if (component instanceof UIKitText) { 
      resolvedStyles.color = `rgba(${component.style?.foreGroundColor?.rgb.r}, ${component.style?.foreGroundColor?.rgb.g}, ${component.style?.foreGroundColor?.rgb.b}, ${component.style?.foreGroundColor?.rgb.a})`;
      resolvedStyles.fontSize = `${component.fontSize}px`;
      resolvedStyles.fontFamily = 'Lexend Variable'; // FIXME: Setting a default font family for now, will need to make it dynamic later

      if (component.headingType == HeadingType.Heading1) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontSize = '2em';
        resolvedStyles.fontWeight = 'bold';
      }
      
      if (component.headingType == HeadingType.Heading2) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontSize = '1.5em';
        resolvedStyles.fontWeight = 'bold';
      }
      
      if (component.headingType == HeadingType.Heading3) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontSize = '1.17em';
        resolvedStyles.fontWeight = 'bold';
      }
      
      if (component.headingType == HeadingType.Heading4) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontWeight = 'bold';
      }
      
      if (component.headingType == HeadingType.Heading5) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontSize = '0.83em';
        resolvedStyles.fontWeight = 'bold';
      }
      
      if (component.headingType == HeadingType.Heading6) { 
        resolvedStyles.display = 'block';
        resolvedStyles.fontSize = '0.67em';
        resolvedStyles.fontWeight = 'bold';
      }
      

      switch (component.textAlignment) {
        case HorizontalAlignment.LeftAligned:
          {
            resolvedStyles.textAlign = "left";
          }
          break;
        case HorizontalAlignment.CenterAligned:
          {
            resolvedStyles.textAlign = "center";
          }
          break;
        case HorizontalAlignment.RightAligned:
          {
            resolvedStyles.textAlign = "right";
          }
      }
    }

    if (component instanceof List) { 
      resolvedStyles.display = "flex";
      resolvedStyles.flexDirection = "column";
    }

    return resolvedStyles;
  };
}
