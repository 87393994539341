import { ReactNode, useEffect, useState } from "react";
import { IUIKitTreeContext, UIKitTreeContext } from "./UIKitTreeContext";
import React from "react";
import { UIKit } from "./utilities/UIKit";
import { UIKitMode } from "./shared/Types";
import { EditorNavigation } from "./EditorNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { renderToString } from "react-dom/server";

export enum PlayerMode {
  Live = "Live",
  Preview = "Preview",
}

export type PlayerProps = {
  mode: PlayerMode;
};

const Player = (props: PlayerProps) => {
  let useUIKitTreeContext: IUIKitTreeContext | null = React.useContext(UIKitTreeContext);

  const [editorWidth, setEditorWidth] = useState(window.innerWidth);
  const [editorHeight, setEditorHeight] = useState(window.innerHeight);

  let [uiKitReactTree, setUIKitReactTree] = useState<ReactNode>();
  let params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => setEditorWidth(window.innerWidth));
    window.addEventListener("resize", () => setEditorHeight(window.innerHeight));

    if (useUIKitTreeContext && params.id && props.mode == PlayerMode.Preview) {
      useUIKitTreeContext.loadAppEnvironmentFromNetwork(params.id);

      if (useUIKitTreeContext?.isLoggedOut) {
        navigate("/login");
      }

      if (useUIKitTreeContext.UIKitTree) {
        setUIKitReactTree(UIKit.createReactNodeTreeFromUIKitComponent(useUIKitTreeContext.UIKitTree, UIKitMode.Live));
      }
    } else if (useUIKitTreeContext && params.id && props.mode == PlayerMode.Live) {
      useUIKitTreeContext.loadLiveAppEnvironmentFromNetwork(params.id);

      if (useUIKitTreeContext.UIKitTree) {
        setUIKitReactTree(UIKit.createReactNodeTreeFromUIKitComponent(useUIKitTreeContext.UIKitTree, UIKitMode.Live));
      }
    }
  }, [useUIKitTreeContext?.UIKitTree]);

  return (
    <div style={{ minWidth: `${(editorWidth / 100) * 100}px`, minHeight: `${(editorHeight / 100) * 95}px` }}>
      {props.mode == PlayerMode.Preview && (
        <EditorNavigation
          appName={useUIKitTreeContext.appEnvironment?.name ?? "Unknown name"}
          userId={useUIKitTreeContext.currentUser?.id ?? ""}
        />
      )}
      {uiKitReactTree}
    </div>
  );
};

export default Player;
