import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ModalType, UIKitTreeContext } from "./UIKitTreeContext";
import React from "react";
import { AppListModal, AppWizardModal } from "./AppWizardModal";

type EditorNavigationProps = {
  userId: string;
  appName?: string;
  showAppWizardModal?: boolean;
  showAppListModal?: boolean;
};

export const EditorNavigation = (props: EditorNavigationProps) => {
  const useUIKitTreeContext = React.useContext(UIKitTreeContext);

  interface Tab {
    name: string;
    href: string;
    current: boolean;
  }

  const [tabs, setTabs] = useState<Tab[]>([]);

  const [showAppWizard, setShowAppWizard] = useState(props.showAppWizardModal);
  const [showAppListModal, setshowAppListModal] = useState(props.showAppListModal);

  useEffect(() => {
    if (useUIKitTreeContext.appEnvironment?.id) {
      setTabs([
        {
          name: "Design",
          href: `/editor/builder/${useUIKitTreeContext.appEnvironment?.id}`,
          current: window.location.pathname === `/editor/builder/${useUIKitTreeContext.appEnvironment?.id}`,
        },
        {
          name: "Prompt Builder",
          href: `/editor/data/${useUIKitTreeContext.appEnvironment?.id}`,
          current: window.location.pathname === `/editor/data/${useUIKitTreeContext.appEnvironment?.id}`,
        },
        {
          name: "Preview",
          href: `/editor/preview/${useUIKitTreeContext.appEnvironment?.id}`,
          current: window.location.pathname === `/editor/preview/${useUIKitTreeContext.appEnvironment?.id}`,
        },
      ]);
    }
  }, [useUIKitTreeContext, useUIKitTreeContext.appEnvironment?.id]);

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      current: i === index,
    }));

    setTabs(updatedTabs);
  };

  const userNavigation = [
    {
      name: "Generate a new app",
      href: "#",
      onClick: () => {
        useUIKitTreeContext.setCurrentModalState(ModalType.AppWizardModal);
      },
    },
    {
      name: "My Apps",
      href: "#",
      onClick: () => {
        useUIKitTreeContext.setCurrentModalState(ModalType.AppsListModal);
      },
    },
    { name: "Sign out", href: "#" },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (useUIKitTreeContext?.currentModal == ModalType.AppsListModal) {
      setshowAppListModal(true);
    } else {
      setshowAppListModal(false);
    }

    if (useUIKitTreeContext?.currentModal == ModalType.AppWizardModal) {
      setShowAppWizard(true);
    } else {
      setShowAppWizard(false);
    }
  }, [useUIKitTreeContext?.currentModal]);

  return (
    <div className="min-h-full">
      {showAppWizard && <AppWizardModal></AppWizardModal>}
      {showAppListModal && <AppListModal userId={useUIKitTreeContext?.currentUser?.id ?? ""}></AppListModal>}
      <Disclosure
        as="nav"
        className="flex justify-between items-center border-b border-indigo-300 border-opacity-25 bg-gray-800 lg:border-none h-16 p-6"
      >
        {({ open }) => (
          <>
            <a
              key={""}
              href={""}
              className={classNames(
                false ? "bg-gray-100 text-gray-700" : "text-white hover:text-gray-200",
                "rounded-md py-2 text-sm font-medium"
              )}
              onClick={(event) => {}}
            >
              {/* <Link to={tab.href}>{tab.name}</Link> */}
            </a>
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "bg-gray-100 text-gray-700" : "text-white hover:text-gray-200 ",
                    "rounded-md px-3 py-2 text-sm font-medium text-center"
                  )}
                  onClick={(event) => {
                    handleTabClick(index);
                  }}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <Link to={tab.href}>{tab.name}</Link>
                </a>
              ))}
            </nav>
            <div className="hidden lg:ml-4 lg:block flex">
              <div className="flex items-center">
                <button
                  type="button"
                  className="relative flex-shrink-0 rounded-full bg-gray-800 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3 flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <span className="font-semibold text-white">{useUIKitTreeContext?.currentUser?.email}</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={item.onClick}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};
