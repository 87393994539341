import React, { ReactElement } from "react";
import DropdownMenu from "../../../DropdownMenu";
import { UIKitMode } from "../../../shared/Types";
import { UIKitContainer } from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import { UIKitContainerLayoutType, VerticalAlignment } from "../../Container";


const ControlContainerColumnAlignment = (): ReactElement => { 
    let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);

    const columnLayoutTypes: string[] = Object.values(VerticalAlignment);

    if (
      useUIKitTreeContext.selectedUIKitComponent &&
      useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
    ) {
      let uiKitContainer =
        useUIKitTreeContext.selectedUIKitComponent as UIKitContainer;

      return (
        <>
          {uiKitContainer.layoutType == UIKitContainerLayoutType.Column && (
              <div className="w-full mt-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Container Elements Alignment
                </label>
                <div className="flex mt-2">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className={"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.columnLayoutType == VerticalAlignment.TopAligned ? " bg-gray-100" : "bg-white")}
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.columnLayoutType = VerticalAlignment.TopAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Top Aligned
                  </button>
                  <button
                    type="button"
                    className={"relative -ml-px inline-flex  items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.columnLayoutType == VerticalAlignment.CenterAligned ? " bg-gray-100" : "bg-white") }
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.columnLayoutType = VerticalAlignment.CenterAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Center Aligned
                  </button>
                  <button
                    type="button"
                    className={"relative -ml-px inline-flex rounded-r-md items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                    + (useUIKitTreeContext.selectedUIKitComponent.columnLayoutType == VerticalAlignment.BottomAligned ? " bg-gray-100" : "bg-white") }
                    onClick={() => {
                      if (
                        useUIKitTreeContext.selectedUIKitComponent && useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                      ) {
                        useUIKitTreeContext.selectedUIKitComponent.columnLayoutType = VerticalAlignment.BottomAligned
                        useUIKitTreeContext.updateUIKitTreeState();
                        useUIKitTreeContext.updateComponentTree();
                      }
                    }}
                  >
                    Bottom Aligned
                  </button>
                </span>
                </div>
              </div>
          )}
        </>
      );
    }
    return <></>;
}

export default ControlContainerColumnAlignment;