import React, { ReactElement } from "react";
import DropdownMenu from "../../../DropdownMenu";
import { UIKitMode } from "../../../shared/Types";
import { UIKitContainer } from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import { UIKitContainerLayoutType, HorizontalAlignment } from "../../Container";


const ControlBaseColumnItemHorizontalAlignment = (): ReactElement => {
    let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);

  const horizontalAlignmentTypes: string[] = Object.values(HorizontalAlignment);

  if (
    useUIKitTreeContext.selectedUIKitComponent &&
    useUIKitTreeContext.selectedUIKitComponent.parent instanceof UIKitContainer
  ) {
    let selectedUIKitComponentParentContainer = useUIKitTreeContext
      .selectedUIKitComponent.parent as UIKitContainer;

    return (
      <>
        {selectedUIKitComponentParentContainer.layoutType ==
          UIKitContainerLayoutType.Column && (

            <div className="w-full mt-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Self Alignment
              </label>
              <div className="flex mt-2">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className={"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                  + (useUIKitTreeContext.selectedUIKitComponent.horizontalAlign == HorizontalAlignment.LeftAligned ? " bg-gray-100" : "bg-white")}
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent 
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.horizontalAlign = HorizontalAlignment.LeftAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Left
                </button>
                <button
                  type="button"
                  className={"relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" 
                  + (useUIKitTreeContext.selectedUIKitComponent.horizontalAlign == HorizontalAlignment.CenterAligned ? " bg-gray-100" : "bg-white") }
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.horizontalAlign = HorizontalAlignment.CenterAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Center
                </button>
                <button
                  type="button"
                  className={"relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" + 
                  (useUIKitTreeContext.selectedUIKitComponent.horizontalAlign == HorizontalAlignment.RightAligned ? " bg-gray-100" : "bg-white")}
                  onClick={() => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.horizontalAlign = HorizontalAlignment.RightAligned;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                >
                  Align Right
                </button>
              </span>
              </div>
            </div>
/*           <DropdownMenu
            id=""
            defaultValue={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitContainer)
                .horizontalAlign
            }
            value={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitContainer)
                .horizontalAlign
            }
            label="Horizontal Align:"
            options={horizontalAlignmentTypes}
            mode={UIKitMode.None}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              if (useUIKitTreeContext.selectedUIKitComponent) {
                useUIKitTreeContext.selectedUIKitComponent.horizontalAlign =
                  HorizontalAlignment[
                    event.target.value as keyof typeof HorizontalAlignment
                  ];
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
              }
              
            }}
          ></DropdownMenu> */
        )}
      </>
    );
  }

  return <></>;
};

export default ControlBaseColumnItemHorizontalAlignment;