import React, { ReactElement } from "react";
import DropdownMenu from "../../../DropdownMenu";
import { UIKitMode } from "../../../shared/Types";
import { UIKitAppViewContainer, UIKitContainer } from "../../../shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";
import { VerticalAlignment, UIKitContainerLayoutType } from "../../Container";

const ControlBaseRowItemVerticalAlignment = (): ReactElement => {
  let useUIKitTreeContext: IUIKitTreeContext =
    React.useContext(UIKitTreeContext);

  const verticalAlignmentTypes: string[] = Object.values(VerticalAlignment);

  if (
    useUIKitTreeContext.selectedUIKitComponent &&
    useUIKitTreeContext.selectedUIKitComponent.parent instanceof UIKitContainer
  ) {
    let selectedUIKitComponentParentContainer = useUIKitTreeContext
      .selectedUIKitComponent.parent as UIKitContainer;

    return (
      <>
        {selectedUIKitComponentParentContainer.layoutType ==
          UIKitContainerLayoutType.Row && (
          <DropdownMenu
            id=""
            defaultValue={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitContainer)
                .verticalAlign
            }
            value={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitContainer)
                .verticalAlign
            }
            label="Vertical Align:"
            options={verticalAlignmentTypes}
            mode={UIKitMode.None}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              if (useUIKitTreeContext.selectedUIKitComponent) {
                useUIKitTreeContext.selectedUIKitComponent.verticalAlign =
                  VerticalAlignment[
                    event.target.value as keyof typeof VerticalAlignment
                  ];

                  useUIKitTreeContext.updateUIKitTreeState()
                  useUIKitTreeContext.updateComponentTree();
              }
            }}
          ></DropdownMenu>
        )}
      </>
    );
  } else { 
    return ( 
      <>
          {UIKitContainerLayoutType.Row && (
          <DropdownMenu
            id=""
            defaultValue={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitAppViewContainer)
                .verticalAlign
            }
            value={
              (useUIKitTreeContext.selectedUIKitComponent as UIKitAppViewContainer)
                .verticalAlign
            }
            label="Vertical Align:"
            options={verticalAlignmentTypes}
            mode={UIKitMode.None}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              if (useUIKitTreeContext.selectedUIKitComponent) {
                useUIKitTreeContext.selectedUIKitComponent.verticalAlign =
                  VerticalAlignment[
                    event.target.value as keyof typeof VerticalAlignment
                  ];

                  useUIKitTreeContext.updateUIKitTreeState()
                  useUIKitTreeContext.updateComponentTree();
              }
            }}
          ></DropdownMenu>)}
      </>
    )
  }

  return <></>;
};

export default ControlBaseRowItemVerticalAlignment;
