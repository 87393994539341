import { useNavigate, useParams } from "react-router-dom";
import { EditorNavigation } from "./EditorNavigation";
import { UIKitComponent, UIKitComponentType } from "./shared/UIKitComponent";
import { IUIKitTreeContext, UIKitTreeContext } from "./UIKitTreeContext";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

type UIElement = {
  id: string;
  name: string;
};

export interface PromptBlock {
  id: string;
  promptValue: string;
  outputComponentId: string;
  triggerComponentId: string;
}

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

const DataEditor = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  const [editorWidth, setEditorWidth] = useState(window.innerWidth);
  const [editorHeight, setEditorHeight] = useState(window.innerHeight);

  const [uiElements, setUIElements] = useState<UIElement[]>([]);
  const [uiElementsButtons, setUIElementsButtons] = useState<UIElement[]>([]);

  const [prompt, setPrompt] = useState("");
  let params = useParams();

  const [promptBlock, setPromptBlock] = useState<PromptBlock>();
  const [selectedPromptOutputComponentId, setSelectedPromptOutputComponentId] = useState<string>("");
  const [selectedPromptTriggerComponentId, setSelectedPromptTriggerComponentId] = useState<string>("");

  const navigate = useNavigate();

  const getPromptBlockQuery = useQuery({
    queryKey: ["appEnvironment", useUIKitTreeContext.appEnvironment?.id],
    queryFn: async () => {
      const response = await fetch(`${API_ENDPOINT_URL}/api/prompt_block/${useUIKitTreeContext.appEnvironment?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });

      const data = await response.json();
      return data;
    },
  });

  useEffect(() => {
    if (useUIKitTreeContext?.isLoggedOut) {
      navigate("/login");
    }

    if (getPromptBlockQuery.data && useUIKitTreeContext.appEnvironment?.id) {
      setPromptBlock({
        id: getPromptBlockQuery.data.id,
        promptValue: getPromptBlockQuery.data.prompt,
        outputComponentId: getPromptBlockQuery.data.outputComponentId,
        triggerComponentId: getPromptBlockQuery.data.triggerComponentId,
      });

      if (useUIKitTreeContext.UIKitTree) {
        setPrompt(formatPromptForEditor(getPromptBlockQuery.data.prompt));
      }
      setSelectedPromptOutputComponentId(getPromptBlockQuery.data.outputComponentId);
      setSelectedPromptTriggerComponentId(getPromptBlockQuery.data.triggerComponentId);
    }
  }, [
    getPromptBlockQuery.data,
    useUIKitTreeContext.appEnvironment?.id,
    useUIKitTreeContext.UIKitTree,
    useUIKitTreeContext.isLoggedOut,
  ]);

  const formatPromptForDatabase = (prompt: string) => {
    // Replace all {{elementName}} with {{elementId}}
    let formattedPrompt = prompt;
    const matches = prompt.match(/{{(.*?)}}/g);
    if (matches) {
      matches.forEach((match) => {
        const elementName = match.substring(2, match.length - 2);
        if (elementName && useUIKitTreeContext?.UIKitTree) {
          const element = UIKitComponent.findByName(elementName, useUIKitTreeContext?.UIKitTree);
          const elementId = element?.id;
          if (elementId) {
            formattedPrompt = formattedPrompt.replace(match, `{{${elementId}}}`);
          }
        }
      });
    }
    return formattedPrompt;
  };

  const formatPromptForEditor = (prompt: string) => {
    // Replace all {{elementId}} with {{elementName}}
    let formattedPrompt = prompt;
    const matches = prompt.match(/{{(.*?)}}/g);
    if (matches) {
      matches.forEach((match) => {
        const elementId = match.substring(2, match.length - 2);
        if (elementId && useUIKitTreeContext?.UIKitTree) {
          const element = UIKitComponent.findById(elementId, useUIKitTreeContext?.UIKitTree);
          const elementName = element?.name;
          if (elementName) {
            formattedPrompt = formattedPrompt.replace(match, `{{${elementName}}}`);
          }
        }
      });
    }
    return formattedPrompt;
  };

  const createPromptBlockInDatabase = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/prompt_block`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        prompt: useUIKitTreeContext.UIKitTree && formatPromptForDatabase(prompt),
        outputComponentId: selectedPromptOutputComponentId,
        appEnvironmentId: params.id,
        triggerComponentId: selectedPromptTriggerComponentId,
      }),
    });

    if (!response.ok && response.status == 401) {
      // Logout the user if they're unauthorized
      useUIKitTreeContext.setIsLoggedOut(true);
    }

    return await response.json();
  };

  const updatePromptBlockInDatabase = async () => {
    const response = await fetch(`${API_ENDPOINT_URL}/api/prompt_block/${params.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token") ?? "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        prompt: useUIKitTreeContext.UIKitTree && formatPromptForDatabase(prompt),
        outputComponentId: selectedPromptOutputComponentId,
        triggerComponentId: selectedPromptTriggerComponentId,
      }),
    });

    if (!response.ok && response.status == 401) {
      // Logout the user if they're unauthorized
      useUIKitTreeContext.setIsLoggedOut(true);
    }

    return await response.json();
  };

  const createPromptBlockInDatabaseMutation = useMutation({
    mutationFn: createPromptBlockInDatabase,
    onSuccess: (response) => {
      setPromptBlock({
        id: response.id,
        promptValue: response.prompt,
        outputComponentId: response.outputComponentId,
        triggerComponentId: response.triggerComponentId,
      });
    },
  });

  const updatePromptBlockInDatabaseMutation = useMutation({
    mutationFn: updatePromptBlockInDatabase,
    onSuccess: (response) => {
      setPromptBlock({
        id: response.id,
        promptValue: response.prompt,
        outputComponentId: response.outputComponentId,
        triggerComponentId: response.triggerComponentId,
      });
    },
  });

  useEffect(() => {
    if (params.id) {
      useUIKitTreeContext?.loadAppEnvironmentFromNetwork(params.id);

      if (useUIKitTreeContext?.UIKitTree) {
        setUIElements(getComponents(useUIKitTreeContext?.UIKitTree, []));
        setUIElementsButtons(getButtonComponents(useUIKitTreeContext?.UIKitTree, []));
      }
    }
  }, [useUIKitTreeContext?.UIKitTree]);

  const getComponents = (component: UIKitComponent, uiElements: UIElement[]) => {
    if (component.children) {
      if (component.type === UIKitComponentType.TextInput) {
        uiElements.push({
          id: component.id,
          name: component.name,
        });
      }
    }
    for (let child of component.children) {
      getComponents(child, uiElements);
    }

    return uiElements;
  };

  const getButtonComponents = (component: UIKitComponent, uiElements: UIElement[]) => {
    if (component.children) {
      if (component.type === UIKitComponentType.Button) {
        uiElements.push({
          id: component.id,
          name: component.name,
        });
      }
    }
    for (let child of component.children) {
      getButtonComponents(child, uiElements);
    }

    return uiElements;
  };

  useEffect(() => {
    window.addEventListener("resize", () => setEditorWidth(window.innerWidth));
    window.addEventListener("resize", () => setEditorHeight(window.innerHeight));
  });

  return (
    <>
      <EditorNavigation
        appName={useUIKitTreeContext.appEnvironment?.name ?? "Unknown name"}
        userId={useUIKitTreeContext?.currentUser?.id ?? ""}
      />
      <div
        className="flex flex-col bg-gray-100 p-8"
        style={{ minWidth: `${(editorWidth / 100) * 100}px`, minHeight: `${(editorHeight / 100) * 95}px` }}
      >
        <div className="w-full flex flex-col gap-y-6">
          <div className="flex flex-row gap-x-4 justify-space-between">
            <label className="text-xl font-bold leading-6 text-gray-900">1. Prompt Input</label>

            <button
              type="button"
              className="rounded bg-white w-32 px-2 py-1 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                if (!promptBlock) {
                  createPromptBlockInDatabaseMutation.mutate();
                } else {
                  updatePromptBlockInDatabaseMutation.mutate();
                }
              }}
            >
              Save
            </button>
          </div>
          <div className="flex flex-col w-full">
            <textarea
              rows={10}
              className="w-full rounded-t-md  border-0 bg-gray-200 px-4 py-2.5 focus:ring-0"
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
            ></textarea>

            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2 bg-gray-200 p-4">
                <label className="text-sm font-semibold leading-6 text-gray-900 mb-2">
                  Add these elements to get user input into your prompt:
                </label>
                <div className="flex flex-row gap-x-4">
                  {uiElements.map((element) => (
                    <button
                      className="rounded-2xl bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={() => {
                        setPrompt(prompt + `{{${element.name}}}`);
                      }}
                    >
                      + {element.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-4">
            <label className="text-xl font-bold leading-6 text-gray-900">2. Prompt Output</label>

            <div className="flex flex-col gap-y-2 bg-gray-200 rounded-md p-4">
              <label className="text-sm font-semibold leading-6 text-gray-900 mb-2 ">
                Select which element you would like the text output of the prompt to be shown on:
              </label>
              <div className="flex flex-row w-full">
                <select
                  className="mr-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-blue-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={selectedPromptOutputComponentId ?? ""}
                  onChange={(event) => {
                    setSelectedPromptOutputComponentId(event.target.value);
                  }}
                >
                  <option value="">Select a output element</option>
                  {uiElements.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-4">
            <label className="text-xl font-bold leading-6 text-gray-900">3. Prompt Trigger</label>

            <div className="flex flex-col gap-y-2 bg-gray-200 rounded-md p-4">
              <label className="text-sm font-semibold leading-6 text-gray-900 mb-2 ">
                Select a element that will trigger your prompt:
              </label>
              <div className="flex flex-row w-full">
                <select
                  className="mr-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-blue-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={selectedPromptTriggerComponentId ?? ""}
                  onChange={(event) => {
                    setSelectedPromptTriggerComponentId(event.target.value);
                  }}
                >
                  <option value="">Select a trigger element</option>
                  {uiElementsButtons.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataEditor;
