import UIKitComponentOverlay from "./components/ComponentOverlay";
import { DropdownSelectProps } from "./shared/Types";

export default function DropdownMenu(props: DropdownSelectProps) {
  return (
    <div className="flex mt-4 flex-row items-center">
      <label className="mr-4 ml-4">{props.label}</label>
      <select
        className="mr-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-blue-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        value={props.value}
      >
        {props.options.map((option) => {
          return <option key={option}>{option}</option>;
        })}
      </select>
    </div>
  );
}
