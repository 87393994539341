import React from "react";
import { UIKitMode, UIProps } from "../shared/Types";

interface TextInputProps extends UIProps {
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiLine?: boolean;
}

const TextInput = (props: TextInputProps) => {
  return (
    <>
    
    {!props.multiLine && (
          <input
          type="text"
          id={props.id}
          style={{...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', pointerEvents: "none" } : {...props.style}}}
          className="block w-full rounded-md border-0 text-gray-900 bg-gray-100 sm:text-sm sm:leading-6"
          onChange={props.onChange}
          defaultValue={props.defaultValue}
        ></input>
    )}

    {props.multiLine && (
          <textarea
          id={props.id}
          style={{...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', pointerEvents: "none" } : {...props.style}}}
          className="block w-full rounded-md border-0 text-gray-900 bg-gray-100 sm:text-sm sm:leading-6"
          defaultValue={props.defaultValue}
        ></textarea>
    )}
    </>

  );
};

export default TextInput;
