import { useDroppable } from "@dnd-kit/core";
import { CSSProperties } from "react";
import { UIKitMode, UIProps } from "../shared/Types";

export enum UIKitContainerLayoutType {
  Row = "Row",
  Column = "Column",
  AlignToParent = "AlignToParent",
  Fixed = "Fixed",
}

export enum AlignToParentAlignment {
  TopLeft = "TopLeft",
  TopCenter = "TopCenter",
  TopRight = "TopRight",
  CenterLeft = "CenterLeft",
  CenterCenter = "CenterCenter",
  CenterRight = "CenterRight",
  BottomLeft = "BottomLeft",
  BottomCenter = "BottomCenter",
  BottomRight = "BottomRight",
}

export enum HorizontalAlignment {
  LeftAligned = "LeftAligned",
  CenterAligned = "CenterAligned",
  RightAligned = "RightAligned",
}

export enum VerticalAlignment {
  TopAligned = "TopAligned",
  CenterAligned = "CenterAligned",
  BottomAligned = "BottomAligned",
}

export let containerLayoutTypeCSS: Record<UIKitContainerLayoutType, string> = {
  Row: "row",
  Column: "column",
  AlignToParent: "",
  Fixed: "",
};

export interface UIKitContainerProps extends UIProps {
  layoutType: UIKitContainerLayoutType;
  rowLayoutType: HorizontalAlignment;
  columnLayoutType: VerticalAlignment;
}

const Container = (props: UIKitContainerProps) => {
  let { setNodeRef } = useDroppable({
    id: props.id,
  });

  return (
    <div
      id={props.id}
      ref={setNodeRef}
      style={{...props.style, ...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', top: '0', left: '0' } : {...props.style} }}
    >
      {props.children}
    </div>
  );
};

export const AppViewContainer = (props: UIKitContainerProps) => {
  let { setNodeRef } = useDroppable({
    id: props.id,
  });
  return (
    <div id={props.id} 
    ref={setNodeRef} 
    style={{...props.style, ...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', backgroundColor: 'white' } : {...props.style} }}>
      {props.children}
    </div>
  );
};

export default Container;
