import React from "react";
import { IUIKitTreeContext, UIKitTreeContext } from "../../../UIKitTreeContext";

function ControlComponentName() { 
    let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);


    return ( 
        <>
        {useUIKitTreeContext.selectedUIKitComponent &&
          <div className="flex mt-4 flex-col">
            <div className="w-full">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Element Name
              </label>
              <div className="mt-2">
                <input
                  className={"block w-full rounded-md border-0 py-1.5 bg-gray-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"}
                  value={
                    useUIKitTreeContext.selectedUIKitComponent.name
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      useUIKitTreeContext.selectedUIKitComponent
                    ) {
                      useUIKitTreeContext.selectedUIKitComponent.name = event.target.value;
                      useUIKitTreeContext.updateUIKitTreeState();
                      useUIKitTreeContext.updateComponentTree();
                    }
                  }}
                />
              </div>
            </div>
            </div>}
        </>

    )
  }

  export default ControlComponentName;