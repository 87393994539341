import { CSSProperties } from "react";

export enum UIKitMode {
  Edit,
  Live,
  None,
}

export interface UIProps {
  id: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  mode: UIKitMode;
}
export interface DropdownSelectProps extends UIProps {
  label: string;
  options: string[];
  defaultValue: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

export enum UIKitComponentValueType { 
  TextInputValue = "TextInputValue",
}

export interface TextProps extends UIProps {
  text?: string
  headingType?: string
}

export interface ButtonProps extends UIProps {
  text?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface ToolBoxItemProps {
  id: string;
  itemName: string;
  icon?: string;
  children?: React.ReactNode;
}

export interface ToolBoxProps {
  id: string;
  items: ToolBoxItemProps[];
}
