import React from "react";
import { ReactNode, useEffect, useState } from "react";
import ControlBaseColumnItemHorizontalAlignment from "./components/Control/Base/ColumnItemHorizontalAlignment";
import ControlBaseHeight from "./components/Control/Base/Height";
import ControlBaseOrder from "./components/Control/Base/Order";
import ControlBaseRowItemVerticalAlignment from "./components/Control/Base/RowItemVerticalAlignment";
import ControlBaseText from "./components/Control/Base/Text";
import ControlBaseWidth from "./components/Control/Base/Width";
import ControlContainerColumnAlignment from "./components/Control/Container/Column";
import ControlContainerLayoutType, { ControlContainerImageUrl } from "./components/Control/Container/LayoutType";
import ControlContainerRowAlignment from "./components/Control/Container/Row";
import {
  List,
  UIKitComponent,
  UIKitComponentType,
  UIKitContainer,
  UIKitText,
  DataSource,
  HeadingType,
  UIKitAppViewContainer,
} from "./shared/UIKitComponent";
import ControlComponentName from "./components/Control/Base/ComponentName";
import { IUIKitTreeContext, UIKitTreeContext } from "./UIKitTreeContext";
import { useQuery } from "@tanstack/react-query";
import { HorizontalAlignment, VerticalAlignment } from "./components/Container";
import { UIKit } from "./utilities/UIKit";
import { ChromePicker, BlockPicker, SketchPicker, ColorResult, Color, RGBColor } from "react-color";

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export interface AirtableTableModel {
  id: string;
  primaryFieldId: string;
  name: string;
  description?: string;
  fields: AirtableField[];
  views: AirtableView[];
}
export interface AirtableField {
  id: string;
  type?: FieldType;
  name: string;
  description?: string;
  options?: FieldOptions;
}

interface AirtableView {
  id: string;
  type: "grid" | "form" | "calendar" | "gallery" | "kanban" | "timeline" | "block";
  name: string;
  visibleFieldIds?: string[];
}

type FieldType =
  | "singleLineText"
  | "email"
  | "url"
  | "multiLineText"
  | "number"
  | "percent"
  | "currency"
  | "singleSelect"
  | "multipleSelects"
  | "date"
  | "dateTime"
  | "phoneNumber"
  | "checkbox"
  | "formula"
  | "rollup"
  | "lookup"
  | "createdTime"
  | "lastModifiedTime"
  | "autoNumber"
  | "barcode"
  | "rating"
  | "richText";

interface FieldOptions {
  // Structure of field options can be added here based on your specific requirements
}
interface ComponentPropertiesProps {
  selectedUIKitComponent: UIKitComponent | undefined;
}

enum ContainerProperties {
  LayoutType,
  RowLayoutType,
  ColumnLayoutType,
}

enum ButtonProperties {
  Text,
}

enum TextProperties {
  Text,
}

const GoToParentContainerLink = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      {" "}
      {useUIKitTreeContext.selectedUIKitComponent?.parent !== undefined && (
        <div className="w-full mt-4">
          <div className="flex flex-col">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-black mb-2">
              Parent Container
            </label>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={() => {
                if (useUIKitTreeContext.selectedUIKitComponent) {
                  useUIKitTreeContext.setSelectedUIKitComponentState(
                    useUIKitTreeContext.selectedUIKitComponent?.parent
                  );
                }
              }}
            >
              {useUIKitTreeContext.selectedUIKitComponent?.parent?.name} (Click to open)
            </button>
          </div>

          <div className="flex mt-2">
            <span className="isolate inline-flex rounded-md shadow-sm"></span>
          </div>
        </div>
      )}
    </>
  );
};

export enum ListItemFieldTypes {
  Text = "Text",
}

interface ListItemFieldProps {
  id: string;
  type: ListItemFieldTypes;
  fields: AirtableField[];
  field?: AirtableField;
}

const ListItemField = (props: ListItemFieldProps) => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);
  const [selectedFieldId, setSelectedFieldId] = useState<string | undefined>(props.field?.id);
  return (
    <>
      <div className="flex flex-col w-full mb-4 border-2 rounded-md divide-y">
        <label className="text-md font-bold text-black">{props.type}</label>

        <div className="flex flex-row w-full space-x-2 p-2  ">
          <label htmlFor="email" className="flex w-20 flex-col justify-center text-sm font-medium leading-6 text-black">
            Label:
          </label>
          <input
            type="text"
            id={props.id}
            style={{}}
            className="block w-full rounded-md border-0 text-gray-900 bg-gray-100 sm:text-sm sm:leading-6"
          ></input>
        </div>

        <div className="flex flex-row w-full space-x-2 p-2">
          <label htmlFor="email" className="flex w-20 flex-col justify-center text-sm font-medium leading-6 text-black">
            Content:
          </label>
          <select
            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 bg-gray-200 focus:ring-2 focus:ring-black sm:text-sm"
            value={selectedFieldId}
            onChange={(event) => {
              setSelectedFieldId(event.target.value);

              if (useUIKitTreeContext.selectedUIKitComponent instanceof List) {
                let listItem: UIKitComponent | undefined = useUIKitTreeContext.selectedUIKitComponent.children?.find(
                  (listItem) => listItem.id === props.id
                );

                useUIKitTreeContext.selectedUIKitComponent.children?.forEach((listComponent: UIKitComponent) => {
                  console.log(listComponent.id);
                });

                if (listItem) {
                  let selectedField = props.fields.find((field) => field.id === event.target.value);
                  if (selectedField) {
                    listItem.data = { listItemField: selectedField };

                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }
              }
            }}
          >
            <option value="">Select Item</option>
            {props.fields.map((field, index) => (
              <option key={index} value={field.id}>
                {field.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

interface TextStylePropertiesProps {}

export const ForegroundColourProperty = () => {
  const useUIKitTreeContext = React.useContext(UIKitTreeContext);
  const popover: React.CSSProperties = {
    position: "absolute",
    zIndex: "2",
  };

  const cover: React.CSSProperties = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const [showColourPicker, setShowColourPicker] = useState(false);

  const [colour, setColour] = useState<ColorResult | undefined>(
    useUIKitTreeContext?.selectedUIKitComponent && useUIKitTreeContext?.selectedUIKitComponent.style
      ? useUIKitTreeContext?.selectedUIKitComponent.style.backgroundColor
      : undefined
  );

  const [selectedTextStyle, setSelectedTextStyle] = useState<HeadingType | undefined>(
    useUIKitTreeContext && useUIKitTreeContext.selectedUIKitComponent
      ? (useUIKitTreeContext.selectedUIKitComponent as UIKitText).headingType
      : undefined
  );

  return (
    <>
      <div className="flex flex-row space-x-2 p-2">
        <label htmlFor="email" className="flex w-20 flex-col justify-center text-sm font-medium leading-6 text-black">
          Fill
        </label>

        <div className="flex flex-row mt-2 justify-between bg-gray-100 w-full items-center">
          <button
            className="rounded-md border border-gray-300 border-4 bg-gray-100 px-3.5 py-2.5 w-16 h-10 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-700 hover:bg-gray-500"
            onClick={() => setShowColourPicker(true)}
            style={{ backgroundColor: `rgba(${colour?.rgb.r}, ${colour?.rgb.g}, ${colour?.rgb.b}, ${colour?.rgb.a})` }}
          ></button>
          <label className="text-md font-semibold mr-2">{colour?.hex}</label>
        </div>
        {showColourPicker && (
          <div style={popover}>
            <div style={cover} onClick={() => setShowColourPicker(false)} />
            <SketchPicker
              color={colour?.rgb}
              onChange={(color: ColorResult) => {
                setColour(color);
                if (useUIKitTreeContext.selectedUIKitComponent) {
                  if (useUIKitTreeContext.selectedUIKitComponent.style) {
                    useUIKitTreeContext.selectedUIKitComponent.style.foreGroundColor = color;
                  } else {
                    useUIKitTreeContext.selectedUIKitComponent.style = { foreGroundColor: color };
                  }

                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const BackgroundColourProperty = () => {
  const useUIKitTreeContext = React.useContext(UIKitTreeContext);
  const popover: React.CSSProperties = {
    position: "absolute",
    zIndex: "2",
  };

  const cover: React.CSSProperties = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const [showColourPicker, setShowColourPicker] = useState(false);

  const [colour, setColour] = useState<ColorResult | undefined>(
    useUIKitTreeContext?.selectedUIKitComponent && useUIKitTreeContext?.selectedUIKitComponent.style
      ? useUIKitTreeContext?.selectedUIKitComponent.style.backgroundColor
      : undefined
  );

  const [selectedTextStyle, setSelectedTextStyle] = useState<HeadingType | undefined>(
    useUIKitTreeContext && useUIKitTreeContext.selectedUIKitComponent
      ? (useUIKitTreeContext.selectedUIKitComponent as UIKitText).headingType
      : undefined
  );

  return (
    <>
      <div className="flex flex-col mt-2">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          Fill
        </label>

        <div className="flex flex-row mt-2 justify-between bg-gray-100 w-full items-center">
          <button
            className="rounded-md border border-gray-300 border-4 bg-gray-100 px-3.5 py-2.5 w-16 h-10 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-700 hover:bg-gray-500"
            onClick={() => setShowColourPicker(true)}
            style={{ backgroundColor: `rgba(${colour?.rgb.r}, ${colour?.rgb.g}, ${colour?.rgb.b}, ${colour?.rgb.a})` }}
          ></button>
          <label className="text-md font-semibold mr-2">{colour?.hex}</label>
        </div>
        {showColourPicker && (
          <div style={popover}>
            <div style={cover} onClick={() => setShowColourPicker(false)} />
            <SketchPicker
              color={colour?.rgb}
              onChange={(color: ColorResult) => {
                setColour(color);
                if (useUIKitTreeContext.selectedUIKitComponent) {
                  if (useUIKitTreeContext.selectedUIKitComponent.style) {
                    useUIKitTreeContext.selectedUIKitComponent.style.backgroundColor = color;
                  } else {
                    useUIKitTreeContext.selectedUIKitComponent.style = { backgroundColor: color };
                  }
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const ContainerWrapProperty = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      <div className="flex flex-col w-full space-x-2 p-2">
        <label htmlFor="email" className="flex w-20 flex-col justify-center text-sm font-medium leading-6 text-black">
          Wrap
        </label>
        <div className="flex flex-row mt-2 space-x-2">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={
                "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" +
                (useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer &&
                useUIKitTreeContext.selectedUIKitComponent.wrapContent
                  ? " bg-gray-100"
                  : "bg-white")
              }
              onClick={() => {
                if (
                  useUIKitTreeContext.selectedUIKitComponent &&
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                ) {
                  useUIKitTreeContext.selectedUIKitComponent.wrapContent = true;
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className={
                "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" +
                (useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer &&
                !useUIKitTreeContext.selectedUIKitComponent.wrapContent
                  ? " bg-gray-100"
                  : "bg-white")
              }
              onClick={() => {
                if (
                  useUIKitTreeContext.selectedUIKitComponent &&
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer
                ) {
                  useUIKitTreeContext.selectedUIKitComponent.wrapContent = false;
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            >
              No
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
const TextStyleProperties = (props: TextStylePropertiesProps) => {
  const useUIKitTreeContext = React.useContext(UIKitTreeContext);

  const [selectedTextStyle, setSelectedTextStyle] = useState<HeadingType | undefined>(
    useUIKitTreeContext && useUIKitTreeContext.selectedUIKitComponent
      ? (useUIKitTreeContext.selectedUIKitComponent as UIKitText).headingType
      : undefined
  );

  return (
    <>
      <div className="flex flex-row w-full space-x-2 p-2">
        <label htmlFor="email" className="flex w-20 flex-col justify-center text-sm font-medium leading-6 text-black">
          Style
        </label>
        <select
          className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 bg-gray-200 focus:ring-2 focus:ring-black sm:text-sm"
          value={selectedTextStyle}
          onChange={(event) => {
            const headingType = event.target.value as HeadingType;

            setSelectedTextStyle(headingType);

            if (useUIKitTreeContext.selectedUIKitComponent instanceof UIKitText) {
              useUIKitTreeContext.selectedUIKitComponent.headingType = headingType;

              useUIKitTreeContext.updateUIKitTreeState();
              useUIKitTreeContext.updateComponentTree();
            }
          }}
        >
          <option value="">Select style</option>
          <option value={HeadingType.Heading1}>Heading 1</option>
          <option value={HeadingType.Heading2}>Heading 2</option>
          <option value={HeadingType.Heading3}>Heading 3</option>
          <option value={HeadingType.Heading4}>Heading 4</option>
          <option value={HeadingType.Heading5}>Heading 5</option>
          <option value={HeadingType.Heading6}>Heading 6</option>
        </select>
      </div>
    </>
  );
};

const ListProperties = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);
  let [tables, setTables] = useState<AirtableTableModel[]>([]);

  const tableFields = (tableId: string) => {
    if (!tables) {
      return [];
    }

    const table = tables.find((table: AirtableTableModel) => table.id === tableId);
    return table?.fields;
  };

  const selectedTableFromTree = () => {
    if (useUIKitTreeContext.selectedUIKitComponent instanceof List) {
      return useUIKitTreeContext.selectedUIKitComponent.dataSource;
    }
  };

  let [selectedTableId, setSelectedTableId] = useState<string | undefined>(selectedTableFromTree()?.id);

  const listItemFieldsFromTree = () => {
    let listItemFields: ListItemFieldProps[] = [];

    if (useUIKitTreeContext.selectedUIKitComponent instanceof List) {
      useUIKitTreeContext?.selectedUIKitComponent?.children?.forEach((listComponent: UIKitComponent) => {
        //TODO: do not hardcode the type
        listItemFields.push({
          id: listComponent.id,
          type: ListItemFieldTypes.Text,
          field: listComponent.data?.listItemField,
          fields: tableFields(selectedTableId ?? "") ?? [],
        });
      });

      return listItemFields;
    }
  };

  let [listItemFields, setListItemFields] = useState<ListItemFieldProps[]>(listItemFieldsFromTree() ?? []);

  let [showListItemFieldTypePicker, setShowListItemFieldTypePicker] = useState(false);

  const airTableTablesQuery = useQuery({
    queryKey: ["airtableTables"],
    queryFn: async () => {
      const response = await fetch(`${API_ENDPOINT_URL}/api/air_table/tables/${"appDd8l9vmHZfM9ux"}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token") ?? "",
          Accept: "application/json",
        },
      });

      const data = await response.json();

      return data;
    },
  });

  const getTable = (tableId: string) => {
    const table = tables.find((table: AirtableTableModel) => table.id === tableId);
    return table;
  };

  useEffect(() => {
    if (airTableTablesQuery.data && airTableTablesQuery.isFetched && airTableTablesQuery.data.tables) {
      let tables: AirtableTableModel[] = [];

      airTableTablesQuery.data.tables.forEach((table: AirtableTableModel) => {
        tables.push(table);
      });

      setTables(tables);
    }
  }, [airTableTablesQuery.isFetched, airTableTablesQuery.data]);

  return (
    <>
      <div className="flex flex-col w-full mt-4">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-black mb-2">
          Data Source
        </label>
        <div className="flex flex-row w-full">
          <select
            className="mr-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-blue-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            value={selectedTableId}
            onChange={(event) => {
              setSelectedTableId(event.target.value);
              let selectedAirTableTable = getTable(event.target.value ?? "");

              if (useUIKitTreeContext.selectedUIKitComponent instanceof List && selectedAirTableTable) {
                useUIKitTreeContext.selectedUIKitComponent.dataSource = getTable(event.target.value ?? "");
                useUIKitTreeContext.selectedUIKitComponent.children = [];

                useUIKitTreeContext.updateUIKitTreeState();
                useUIKitTreeContext.updateComponentTree();
              }
            }}
          >
            <option value="">Select a table</option>
            {airTableTablesQuery.data &&
              airTableTablesQuery.isFetched &&
              tables.map((table, index) => (
                <option key={index} value={table.id}>
                  {table.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="flex flex-col w-full mt-4">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-black mb-2">
          List Item Fields
        </label>
        <div className="flex flex-col w-full">
          {listItemFields.map((listItemField, index) => (
            <ListItemField
              id={listItemField.id}
              key={index}
              type={listItemField.type}
              field={listItemField.field}
              fields={tableFields(selectedTableId ?? "") ?? []}
            />
          ))}

          {!showListItemFieldTypePicker && (
            <button
              className="rounded-2xl bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => {
                setShowListItemFieldTypePicker(true);
              }}
            >
              + Add Field
            </button>
          )}

          {showListItemFieldTypePicker && (
            <div className="flex flex-col">
              <button
                className="hover:bg-gray-100"
                onClick={() => {
                  setShowListItemFieldTypePicker(false);

                  let selectedAirTableTable = getTable(selectedTableId ?? "");

                  if (useUIKitTreeContext.selectedUIKitComponent instanceof List && selectedAirTableTable) {
                    let id = UIKit.generateUIKitComponentID(UIKitComponentType.Text);
                    useUIKitTreeContext.selectedUIKitComponent.children?.push(
                      new UIKitText(
                        {
                          type: UIKitComponentType.Text,
                          id: id,
                          name: "Text",
                          parent: useUIKitTreeContext.selectedUIKitComponent
                            ? useUIKitTreeContext.selectedUIKitComponent
                            : undefined,
                          children: [],
                          verticalAlign: VerticalAlignment.TopAligned,
                          horizontalAlign: HorizontalAlignment.LeftAligned,
                          width: 140,
                          height: 40,
                          x: 0,
                          y: 0,
                          canDrag: false,
                          fixedWidth: false,
                          fixedHeight: false,
                          fitWidthToContent: true,
                          fitHeightToContent: true,
                          fillHeightToParent: false,
                          fillWidthToParent: false,
                        },
                        "",
                        undefined,
                        16
                      )
                    );

                    setListItemFields([...listItemFields, { type: ListItemFieldTypes.Text, fields: [], id: id }]);

                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Text
              </button>
              <button
                className="hover:bg-gray-100"
                onClick={() => {
                  setShowListItemFieldTypePicker(false);

                  let selectedAirTableTable = getTable(selectedTableId ?? "");

                  if (useUIKitTreeContext.selectedUIKitComponent instanceof List && selectedAirTableTable) {
                    let id = UIKit.generateUIKitComponentID(UIKitComponentType.Text);
                    useUIKitTreeContext.selectedUIKitComponent.children?.push(
                      new UIKitText(
                        {
                          type: UIKitComponentType.Text,
                          id: id,
                          name: "Heading 1 Label",
                          parent: useUIKitTreeContext.selectedUIKitComponent.parent
                            ? useUIKitTreeContext.selectedUIKitComponent.parent
                            : undefined,
                          children: [],
                          verticalAlign: VerticalAlignment.TopAligned,
                          horizontalAlign: HorizontalAlignment.LeftAligned,
                          width: 140,
                          height: 40,
                          x: 0,
                          y: 0,
                          canDrag: false,
                          fixedWidth: false,
                          fixedHeight: false,
                          fitWidthToContent: true,
                          fitHeightToContent: true,
                          fillHeightToParent: false,
                          fillWidthToParent: false,
                        },
                        "Heading 1",
                        HeadingType.Heading1
                      )
                    );

                    setListItemFields([...listItemFields, { type: ListItemFieldTypes.Text, fields: [], id: id }]);

                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Heading 1
              </button>
              <button
                className="hover:bg-gray-100"
                onClick={() => {
                  setShowListItemFieldTypePicker(false);

                  let selectedAirTableTable = getTable(selectedTableId ?? "");

                  if (useUIKitTreeContext.selectedUIKitComponent instanceof List && selectedAirTableTable) {
                    let id = UIKit.generateUIKitComponentID(UIKitComponentType.Text);
                    useUIKitTreeContext.selectedUIKitComponent.children?.push(
                      new UIKitText(
                        {
                          type: UIKitComponentType.Text,
                          id: id,
                          name: "Heading 2 Label",
                          parent: useUIKitTreeContext.selectedUIKitComponent.parent
                            ? useUIKitTreeContext.selectedUIKitComponent.parent
                            : undefined,
                          children: [],
                          verticalAlign: VerticalAlignment.TopAligned,
                          horizontalAlign: HorizontalAlignment.LeftAligned,
                          width: 140,
                          height: 40,
                          x: 0,
                          y: 0,
                          canDrag: false,
                          fixedWidth: false,
                          fixedHeight: false,
                          fitWidthToContent: true,
                          fitHeightToContent: true,
                          fillHeightToParent: false,
                          fillWidthToParent: false,
                        },
                        "Heading 2",
                        HeadingType.Heading2
                      )
                    );

                    setListItemFields([...listItemFields, { type: ListItemFieldTypes.Text, fields: [], id: id }]);

                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Heading 2
              </button>
              <button
                className="hover:bg-gray-100"
                onClick={() => {
                  setShowListItemFieldTypePicker(false);

                  let selectedAirTableTable = getTable(selectedTableId ?? "");

                  if (useUIKitTreeContext.selectedUIKitComponent instanceof List && selectedAirTableTable) {
                    let id = UIKit.generateUIKitComponentID(UIKitComponentType.Text);
                    useUIKitTreeContext.selectedUIKitComponent.children?.push(
                      new UIKitText(
                        {
                          type: UIKitComponentType.Text,
                          id: id,
                          name: "Heading 3 Label",
                          parent: useUIKitTreeContext.selectedUIKitComponent.parent
                            ? useUIKitTreeContext.selectedUIKitComponent.parent
                            : undefined,
                          children: [],
                          verticalAlign: VerticalAlignment.TopAligned,
                          horizontalAlign: HorizontalAlignment.LeftAligned,
                          width: 140,
                          height: 40,
                          x: 0,
                          y: 0,
                          canDrag: false,
                          fixedWidth: false,
                          fixedHeight: false,
                          fitWidthToContent: true,
                          fitHeightToContent: true,
                          fillHeightToParent: false,
                          fillWidthToParent: false,
                        },
                        "Heading 3",
                        HeadingType.Heading3
                      )
                    );

                    setListItemFields([...listItemFields, { type: ListItemFieldTypes.Text, fields: [], id: id }]);

                    useUIKitTreeContext.updateUIKitTreeState();
                    useUIKitTreeContext.updateComponentTree();
                  }
                }}
              >
                Heading 3
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ContainerGapAndPaddingProperties = () => {
  let useUIKitTreeContext: IUIKitTreeContext = React.useContext(UIKitTreeContext);

  return (
    <>
      <div className="flex mt-4 flex-row space-x-4">
        <div className="flex flex-col">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Gap
          </label>

          <div className="flex flex-row mt-2 space-x-2">
            <input
              type="number"
              className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={useUIKitTreeContext.selectedUIKitComponent?.asUIKitContainer()?.flexGap ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  (useUIKitTreeContext.selectedUIKitComponent &&
                    useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer) ||
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitAppViewContainer
                ) {
                  useUIKitTreeContext.selectedUIKitComponent.flexGap = parseInt(event.target.value);
                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Padding
          </label>
          <div className="flex flex-col mt-2 space-x-2">
            <input
              type="number"
              className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={useUIKitTreeContext.selectedUIKitComponent?.asUIKitContainer()?.paddingTop ?? 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  (useUIKitTreeContext.selectedUIKitComponent &&
                    useUIKitTreeContext.selectedUIKitComponent instanceof UIKitContainer) ||
                  useUIKitTreeContext.selectedUIKitComponent instanceof UIKitAppViewContainer
                ) {
                  // TODO: Allow user to set padding for specific sides
                  useUIKitTreeContext.selectedUIKitComponent.paddingTop = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingBottom = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingLeft = parseInt(event.target.value);
                  useUIKitTreeContext.selectedUIKitComponent.paddingRight = parseInt(event.target.value);

                  useUIKitTreeContext.updateUIKitTreeState();
                  useUIKitTreeContext.updateComponentTree();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ComponentProperties = (props: ComponentPropertiesProps) => {
  const uiKitComponentType = props.selectedUIKitComponent ? props.selectedUIKitComponent.type : undefined;

  const [tabs, setTabs] = useState([
    { name: "Display", href: "#", current: true },
    { name: "Layout", href: "#", current: false },
    { name: "Data", href: "#", current: false },
  ]);

  const baseLayoutPropertyControls: ReactNode[] = [
    <ControlBaseRowItemVerticalAlignment />,
    <ControlBaseColumnItemHorizontalAlignment />,
    <ControlBaseWidth />,
    <ControlBaseHeight />,
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      current: i === index,
    }));
    setTabs(updatedTabs);
  };

  const containerPropertyControls = (): ReactNode[] => {
    let propertyControls: ReactNode[] = [];

    propertyControls.push(<GoToParentContainerLink />);
    // propertyControls.push(<ControlContainerImageUrl />);

    for (const property in ContainerProperties) {
      switch (property) {
        case ContainerProperties.LayoutType.toString():
          {
            propertyControls.push(<ControlContainerLayoutType />);
          }
          break;
        case ContainerProperties.RowLayoutType.toString():
          {
            propertyControls.push(<ControlContainerRowAlignment />);
          }
          break;
        case ContainerProperties.ColumnLayoutType.toString(): {
          propertyControls.push(<ControlContainerColumnAlignment />);
        }
      }
    }

    // propertyControls.push(<ContainerWrapProperty />);
    propertyControls.push(<ContainerGapAndPaddingProperties></ContainerGapAndPaddingProperties>);

    return propertyControls;
  };

  const UIKitComponentPropertyControls = (type: UIKitComponentType): ReactNode[] => {
    let propertyControls: ReactNode[] = [];

    propertyControls.push(<ControlComponentName />);
    propertyControls.push(<BackgroundColourProperty />);

    switch (type) {
      case UIKitComponentType.Button:
        {
          for (const property in ButtonProperties) {
            switch (property) {
              case ButtonProperties.Text.toString(): {
                propertyControls.push(<ControlBaseText />);
              }
            }
          }
        }
        break;

      case UIKitComponentType.Text:
        {
          for (const property in TextProperties) {
            switch (property) {
              case TextProperties.Text.toString(): {
                propertyControls.push(<ControlBaseText />);
                propertyControls.push(<TextStyleProperties />);
              }
            }
          }
        }
        break;

      case UIKitComponentType.TextInput: {
        for (const property in TextProperties) {
          switch (property) {
            case TextProperties.Text.toString(): {
              propertyControls.push(<ControlBaseText />);
            }
          }
        }
        break;
      }

      case UIKitComponentType.List: {
        propertyControls.push(<ListProperties />);
      }
    }

    return propertyControls;
  };

  return (
    <>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-sky-500 text-sky-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={(event) => {
                  handleTabClick(index);
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      {tabs[0].current && uiKitComponentType && UIKitComponentPropertyControls(uiKitComponentType)}

      {tabs[1].current && uiKitComponentType && (
        <div className="flex mt-4 flex-col">
          <>
            {" "}
            {containerPropertyControls()} {baseLayoutPropertyControls}{" "}
          </>{" "}
        </div>
      )}
      {/* 
      {tabs[2].current && (
        <>
          <div className="w-full mt-2 flex space-y-2 flex-col">
            <label className="block text-sm font-medium leading-6 text-gray-900">On Load</label>
            <div className="flex mt-2">
              <button className="overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full">
                Add Event
              </button>
            </div>

            <label className="block text-sm font-medium leading-6 text-gray-900">On Click</label>
            <div className="flex mt-2">
              {!isAddingEvent && (
                <button
                  className="overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full"
                  onClick={() => {
                    setIsAddingEvent(true);
                  }}
                >
                  Add Event
                </button>
              )}

              {isAddingEvent && (
                <div className="flex flex-col overflow-hidden rounded-md bg-white px-6 py-4 shadow block text-md font-medium leading-6 text-gray-900 text-center w-full">
                  <div className="flex flex-col">
                    <button className="text-m pl-2 text-center hover:bg-gray-100" onClick={() => {}}>
                      Navigate to Screen
                    </button>
                    <button className="text-m pl-2 text-center hover:bg-gray-100" onClick={() => {}}>
                      Add a Document
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )} */}
    </>
  );
};

export default ComponentProperties;
