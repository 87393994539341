import UIKitComponentOverlay from "./ComponentOverlay";
import { ButtonProps, UIKitMode } from "../shared/Types";

export default function Button(props: ButtonProps) {
  return (
      <button
        id={props.id}
        style={{...props.mode == UIKitMode.Edit ? { width: '100%', height: '100%', pointerEvents: "none" } : {...props.style}}}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={props.onClick}
      >
        {props.text}
      </button>
  );
}
