import { ReactElement } from "react"
import { TextProps, UIKitMode } from "../shared/Types"
import { HeadingType } from "../shared/UIKitComponent";

const Text = (props: TextProps): ReactElement => { 
    return ( 
        <>
                {props.headingType === HeadingType.Heading1 && (
                    <h1 id={props.id} style={props.style}>{props.text}</h1>
                )}

                {props.headingType === HeadingType.Heading2 && (
                    <h2 id={props.id} style={props.style}>{props.text}</h2>
                )}

                {props.headingType === HeadingType.Heading3 && (
                    <h3 id={props.id} style={props.style}>{props.text}</h3>
                )}

                {props.headingType === HeadingType.Heading4 && (
                    <h4 id={props.id} style={props.style}>{props.text}</h4>
                )}

                {props.headingType === HeadingType.Heading5 && (
                    <h5 id={props.id} style={props.style}>{props.text}</h5>
                )}

                {props.headingType === HeadingType.Heading6 && (
                    <h6 id={props.id} style={props.style}>{props.text}</h6>
                )}

                {props.headingType === undefined && (
                    <p id={props.id} style={props.style}>{props.text}</p>
                )}
                
        </>

    )
}

export default Text;